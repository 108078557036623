<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ activeStep.pageTitle }}</h3>
      </AppCardHeader>
    </template>
    <form @submit.prevent="submit">
      <AppDynamicForm :fields="startFormFields" :disabled="isLoading" />
      <AppDynamicForm :fields="lastFormFields" :disabled="isLoading" />
      <div class="sub-card">
        <div class="p-6">
          <div class="flex w-full justify-between items-center">
            <span><AppInputToggle v-model="businessSameOperatingAddress" /></span>
            <p class="text-sm text-gray pl-2.5 leading-5">
              My business operates from a different address
            </p>
          </div>
        </div>
      </div>
      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading"> Continue </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'
import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppInputToggle from '@/components/AppInputToggle/AppInputToggle'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'

import { useAnalyticsStore } from '@/stores/analytics'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '../../stores/auth'
import { useAppStore } from '@/stores/app'

export default {
  name: 'BusinessAddress',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    IconChevronLeft,
    AppDynamicForm,
    AppInputToggle,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()

    corporateRegistrationStore.activeStepIdx = 2

    const getCountry = companyDetailsStore.companyCountry
    const title =
      getCountry === 'GB' || getCountry === 'MY' ? 'Registered address' : 'Business address'
    corporateRegistrationStore.setActiveStepPageTitle(title)
    const { activeStep, businessSameOperatingAddress } = storeToRefs(corporateRegistrationStore)

    const fieldGroupName = 'registeredaddress'

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    const isSubmitButtonDisabled = computed(() => {
      return !hasFormFields.value || isFormInvalid.value
    })

    let { fields } = corporateRegistrationStore.getNewCustomerFields(fieldGroupName)

    // Remove country label to fit design
    fields.forEach((field) => {
      if (field.id === 'country') {
        field.label = ''
      }
    })

    if (fields) {
      setupFields(fields, fieldGroupName)
    }

    // Delete leading & trailing spaces on input text after onblur event
    const deleteSpaceOnInputText = (object) => {
      if (object) {
        // Delete leading & trailing spaces on input text after onblur event
        object.forEach((field, i) => {
          if (field.component.name === 'default' || field.component.name === '_default') {
            object[i]['listeners'] = {
              blur: (evt) => {
                if (field.value === evt.target.value) {
                  field.value = cleanTrailingSpace(field.value)
                }
              },
            }
          }
        })
      }
    }

    const stateField = formFields.value.find((field) => field.id === 'state')
    const postalCodeField = formFields.value.find((field) => field.id === 'postalcode')
    let lastFormFields, startFormFields
    if (stateField && postalCodeField) {
      lastFormFields = [stateField, postalCodeField]
      startFormFields = formFields.value.filter((field) => field.id !== 'state' && field.id !== 'postalcode')
    } else {
      startFormFields = formFields.value
    }

    // Sorts input fields on the 'Business address' page according to the order below
    let businessAddressOrderIds = ['country', 'unit', 'streetnumber', 'streetname', 'streettype', 'line1', 'suburb', 'city', 'postalcode']
    startFormFields.sort((a, b) => {
      return businessAddressOrderIds.findIndex((item) => item === a.id) - businessAddressOrderIds.findIndex((item) => item === b.id)
    })

    // Set onblur events for clean input text components
    deleteSpaceOnInputText(startFormFields)
    deleteSpaceOnInputText(lastFormFields)

    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }

    const submit = async () => {
      isLoading.value = true
      try {
        const errors = await corporateRegistrationStore.submitFieldData({
          group: fieldGroupName,
          formFields,
        })
        if (errors && errors.length > 0) {
          corporateRegistrationStore.showServerErrors({ errors, formFields })
        } else {
          const streetType = getFieldValue('streettype')
          analyticsStore.identify({
            userId: authStore.profileId,
            traits: {
              cityOfBusiness: getFieldValue('city'),
              zipcodeOfBusiness: getFieldValue('postalcode'),
              stateOfBusiness: getFieldValue('state'),
              // adding street type props conditionally
              ...(streetType && {
                streetTypeOfBusiness: streetType,
              }),
            },
          })
          analyticsStore.track({
            event: 'Address Details Completed',
            traits: {
              addressType: 'Business',
              sameOperatingAddress: !businessSameOperatingAddress.value,
              referenceSource: 'XEMT Business',
              email: authStore.lastLogin,
              // adding street type props conditionally
              ...(streetType && {
                streetType: streetType,
              }),
            },
          })
          if (!businessSameOperatingAddress.value) {
            await corporateRegistrationStore.submitFieldData({
              group: 'tradingaddress',
              formFields,
            })
          }

          if (businessSameOperatingAddress.value) {
            router.push({ name: 'RegisterBusinessAddressSearch' })
          } else {
            router.push({ name: 'RegisterCurrencyAndVolume' })
          }
        }
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submiting business address',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      isLoading.value = false
    }

    const goBack = () => {
      router.replace({ name: 'RegisterAddressSearch' })
    }

    return {
      activeStep,
      isSubmitButtonDisabled,
      submit,
      startFormFields,
      lastFormFields,
      isLoading,
      businessSameOperatingAddress,
      goBack,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-input-checkbox {
  z-index: 0;
}
.sub-card {
  @apply w-full mb-6 border border-gray-light rounded-2xl shadow-ria-1;
}
.horizontal {
  @apply flex flex-row -mx-2;
  /deep/ .input {
    @apply flex flex-col w-2/4 mx-2;
  }
}
</style>
